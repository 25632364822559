.special-label {
    display: none !important;
}

.react-tel-input .form-control {
    padding: 10px 14px 10px 58px !important;
    width: 100% !important;
    z-index: 100;
}

.react-tel-input .country-list {
    z-index: 3 !important;
}

.application-modal {
    z-index: 1299;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.15s ease-in;
    will-change: all;
    &_bg {
        background: #000000bd; 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &_container {
        will-change: transform;
        z-index: 3;
        transition: transform 0.55s cubic-bezier(0.55, 0.04, 0, 1.36);
        transform: translateY(100%);
        max-width: 520px;
        max-height: 620px;
        border-radius: 16px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    &_artists {
        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 220px;
            height: 800px;
            background: url(../../../src/img/applications/bg-right.svg);
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 8;
            top: -140px;
            right: -10px;
          }
          
          &::after {
            content: '';
            position: absolute;
            display: block;
            width: 560px;
            height: 740px;
            background: url(../../../src/img/applications/bg-left.svg);
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 8;
            top: -90px;
            left: 0;
          }
    }
    &_wrapper {
        background: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        transition: transform .3s all;
    }
    &_wrapper::-webkit-scrollbar {
        width: 0;
    }
    &_steps {
        height: 100%;
        padding: 35px 20px 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 10;
    }
    &_step {
        will-change: transform;
        transition: transform 0.45s cubic-bezier(0.54, 0.04, 0, 1.36);
        display: flex;
        flex-direction: column;
        animation: animate-application 0.6s ease-out;
    }
    &_question {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px !important;
        &_title {
            font-weight: 400;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 5px;
        }
        &_input {
            padding: 6px 24px 6px 10px;
            border: 1px solid #637882;
            border-radius: 6px;
            font-weight: 400;
            font-size: 14px;
            outline: none;
        }
        &_error {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.66;
            letter-spacing: 0.03333em;
            color: #d32f2f;
            margin-top: 4px;
            margin-left: 14px;
        }
        &_checkbox {
            display: flex;
            align-items: center;
            height: 35px;
            margin-bottom: 5px;
            input[type="checkbox"] {
                width: 20px;
                height: 20px;
            }
            &_text {
                display: block;
                font-size: 14px;
                margin-left: 5px;
                margin-right: 15px;
            }
        }
        &_radio {
            display: flex;
            align-items: center;
            height: 25px;
            margin-bottom: 5px;
            input[type="radio"] {
                width: 20px;
                height: 20px;
            }
            &_text {
                display: block;
                font-size: 14px;
                margin-left: 5px;
                margin-right: 15px;
            }
        }
    }
    &_navigation {
        z-index: 2;
        margin-top: auto;
        margin-bottom: 20px;
    }
}

.input__wrapper {
  position: relative;
  margin: 15px 0;
  text-align: center;
}
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  &-button {
    height: 90px;
    border: 1px solid #C4C4C4;
    color: #aaaaaa;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
    transition: .1s ease;
    &-text {
        cursor: pointer;
    }
  }
  &-button:hover {
    border: 1px solid #212121;
  }
}

@keyframes animate-application {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
     to {
         opacity: 1;
        transform: translateY(0%);
    }
}


@media (max-width: 500px) {
    .application-modal {
        &_container {
            transition: transform 0.55s cubic-bezier(0.55, 0.04, 0, 1.36);
            margin-bottom: 0;
            border-radius: 16px 16px 0 0;
        }
        &_scroll {
            padding-bottom: 0px;
        }
        &_wrapper {
            background: #fff;
            border-radius: 16px 16px 0 0;
        }
    }
}
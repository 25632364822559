.open-payment {
    .modal-payment {
        opacity: 1;
        pointer-events: all;
        &_container {
            transform: translateY(0%);
        }
    }
}

.bepaid-app {
    background: rgba(0, 0, 0, 0.59);
}


.frame_wrapper {
    height: 100%;
}

.modal-payment::-webkit-scrollbar {
    width: 0;
  }

.modal-payment {
    z-index: 5;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    transition: opacity 0.15s ease-in;
    pointer-events: none;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: opacity;
    &_bg {
        background: #000000bd;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &_close {
        position: absolute;
        cursor: pointer;
        right: 12px;
        top: 17px
    }
    &_container {
        z-index: 3;
        transition: transform 0.45s cubic-bezier(0.54, 0.04, 0, 1.36);
        transform: translateY(100%);
        max-width: 550px;
        max-height: 600px;
        border-radius: 16px;
        // overflow: hidden;
        width: 100%;
        height: 90%;
        height: 90vh;
        display: flex;
        flex-direction: column;
        margin: auto;
        // overflow: hidden;
    }
    &_wrapper {
        height: 100%;
        iframe {
            background: #eaeaee;
            border: none;
            border-radius: 16px;
            // overflow: hidden;
            overflow: auto;
            width: 100%;
            height: 100%;
            //overflow: hidden;
            // overflow-y: hidden;  /*для вертикального*/
            // overflow-x: hidden;  /*для горизонтального*/
        }
    }
    &_close-window {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #000000e7;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        pointer-events: none;
        border: 0;
        padding: 10px;
        transition: opacity 0.15s ease-out;
        border-radius: 0px;
        margin: 0;
        &_text {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
        }
        &_cancel, &_close {
            width: 119px;
            height: 35px;
            border-radius: 10px;
            border: none;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            cursor: pointer;
            transition: .15s;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &_btns {
            margin-top: 15px;
            width: 243px;
            display: flex;
            justify-content: space-between;
        }
        &_cancel {
            background: #FFFFFF;
            color: #000000;
        }
        &_close {
            background: #34C759;
            color: #ffffff;
        }
        
        &_cancel:hover {
            background: #d0d0d0;
            color: #000000;
        }
        &_close:hover {
            background: #36d05d;
            color: #ffffff;
        }
        &_cancel:active {
            background: #b4b4b4;
            color: #000000;
        }
        &_close:active {
            background: #30ba52;
            color: #ffffff;
        }
    }
}   

@media (max-width: 500px) {
    .modal-payment {
        bottom: 0;
        &_container {
            transition: transform 0.55s cubic-bezier(0.54, 0.04, 0, 1.36);
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            height: 100vh;
            border-radius: 0;
        }
        &_wrapper {
            iframe {
                border-radius: 0;
            }
        }
        &_close-window {
            border-radius: 0;
        }
    }
}
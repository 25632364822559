.ticket {
    display: flex;
    border-radius: 16px;
    margin-bottom: 20px;
    transition: transform 0.3s ease-out;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    // position: relative;
    // position: -webkit-sticky;
    // position: sticky;
    // -webkit-transform-origin: top;
    // -ms-transform-origin: top;
    // transform-origin: top;
    // top: 85px;
    z-index: 2;
    &_wrapper {
        display: flex;
        flex-direction: column;
        width: 70%;
        background: #ffffffb3;
        backdrop-filter: blur(7.5px);
        border-radius: 0 16px 16px 0;
        position: relative;
    }

    &_background {
        width: 30%;
        transition: 0.25s;
        border-radius: 16px 0 0 16px;
    }
    &_background_mobile {
        display: none;
        border-radius: 16px 0 0 16px;
    }
    &_information {
        display: flex;
        justify-content: space-between;
        padding: 25px 25px 0 25px;
    }
    &_name {
        &_title {
            margin: 0;
            font-weight: 600;
            font-size: calc(16px + 2 * (100vw / 1280));
            color: #000000;
        }
        &_text {
            display: block;
            font-weight: 400;
            font-size: calc(14px + 2 * (100vw / 1280));
            line-height: 19px;
            color: #000000;
            margin-top: 5px;
            max-height: 19px;
        }
    }
    &_btns {
        display: flex;
        justify-content: space-between;
        padding: 40px 25px 25px 25px;
        &_more,
        &_buy,
        &_sold,
        &_alfa {
            width: 125px;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-decoration: none;
            cursor: pointer;
            transition: 0.25s;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        }
        &_alfa {
            font-size: 14px;
            background: #ffffffb3;
            backdrop-filter: blur(7.5px);
            color: #000000;
            margin-right: 10px;
            width: 150px;
            &_icon {
                width: 26px;
                height: 26px;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
                margin-right: 5px;
            }
        }
        &_alfa:hover {
            background: #F13325;
            color: #ffffff;
        }
        &_more {
            background: #ffffff;
            color: #000000;
            margin-left: auto;
            margin-right: 10px;
        }
        &_more:hover {
            background: #e7e7e7;
        }
        &_more:active {
            background: rgb(208, 208, 208);
        }
        &_buy {
            background: #34c759;
            color: #ffffff;
            font-weight: 600;
        }
        &_sold {
            background: #8d8d8d;
            color: #ffffff;
            font-weight: 600;
            cursor: not-allowed;
            margin-right: 0;
        }
        &_buy:hover {
            background: #39db61;
        }
        &_buy:active {
            background: #2fb450;
        }
    }
    &_price {
        display: flex;
        &_text {
            display: flex;
            align-items: center;
            font-size: 22px;
            font-weight: 900;
            color: #aa2424;
            padding: 0 5px;
            border: 3px solid #aa2424;
            //rotate: 5deg;
            min-width: 128px;
            height: 30px;
        }
        &_current {
            display: flex;
            position: relative;
            &_close {
                rotate: -10deg !important;
                display: none;
                position: absolute;
                top: 10px;
                left: -2px;
                width: 105%;
                height: 3px;
                border-radius: 10px;
                background: #c63030aa;
            }
        }
        &_whole {
            display: block;
            width: max-content;
            text-align: end;
        }
        &_whole,
        &_currency {
            font-weight: 600;
            font-size: 22px;
            color: #000000;
            margin: 0;
        }
        &_fractional {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
        }
        &_currency {
            margin-left: 5px;
        }
        &_all {
            cursor: pointer;
            margin-top: 2px;
            margin-left: 5px;
            position: relative;
        }
    }
    &_prices {
        display: flex;
        align-items: center;
        position: absolute;
        &_info {
            //background: url(../img/info.svg);
            width: 28px;
            height: 28px;
            background-size: contain;
            margin-left: 10px;
            cursor: pointer;
            position: relative;
            &_block {
                display: flex;
                opacity: 0;
                position: absolute;
                right: 5px;
                width: 120px;
                padding: 10px 0;
                background: white;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: auto;
                transition: all 0.3s ease-in-out;
                z-index: 3;
            }
        }
    }
    &_alfa {
        position: absolute;
        top: 0;
        right: 0;
        height: 60px;
        width: 60px;
        overflow: hidden;
        &_btn {
            border-radius: 0px 16px 0px 20px;
            background: #ef3124;
            width: 48px;
            height: 48px;
            //  animation: 2s ticket-pulse infinite;
            transition: .2s;
            position: absolute;
            bottom: 12px;
            left: 12px;
            z-index: 2;
        }
        &_corner {
            width: 48px;
            height: 48px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 0px;
            left: 12px;
            border-bottom-left-radius: 20px;
            z-index: 4;
        }
        &_bg {
            display: none;
            width: 48px;
            height: 48px;
            position: absolute;
            bottom: 12px;
            left: 12px;
            background-color: var(--theme-background);
            border-bottom-left-radius: 20px;
            z-index: 1;
        }
        &_bg-white-h {
            width: 12px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #ffffff;
            z-index: 3;
        }
        &_bg-white-v {
            width: 100%;
            height: 12px;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #ffffff;
            z-index: 3;
        }
        &_bg-white-bl {
            width: 30px;
            height: 30px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #ffffff;
            z-index: 3;
        }
    }
}

@keyframes ticket-pulse {
    0% {
      box-shadow: 0 0 0 0 #ef3124;
    }
    80% {
      box-shadow: 0 0 0 9px transparent;
    }
    100% {
      box-shadow: 0 0 0 0 transparent;
    }
  }

.ticket_btns_sold {
    position: relative;
}

.ticket_btns_sold_text,
.countdown-timer {
    position: absolute;
    opacity: 1;
    //animation: fadeAnimation 8s infinite;
}

.countdown-timer {
    opacity: 0;
    animation-delay: 4s;
}

@keyframes fadeAnimation {
    0%,
    40% {
        opacity: 1;
    }
    45%,
    100% {
        opacity: 0;
    }
}

.price {
    &_old {
        font-weight: 300;
        text-decoration-line: line-through;
    }
    &_now {
        font-weight: 700;
    }
    &_next {
        font-weight: 300;
    }
}

.shine {
    background-image: linear-gradient(
        270deg,
        rgba(100, 239, 100, 0) 48.44%,
        #ffffff96 75.52%,
        rgba(100, 239, 132, 0) 100%
    );
    background-repeat: no-repeat;
    animation: bg-move linear 5s infinite;
    will-change: transform;
}

@keyframes bg-move {
    0% {
        background-position: -500px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

@media (max-width: 670px) {
    .ticket {
        &_background {
            background-image: none;
            width: 25px;
            display: none;
        }
        &_background_mobile {
            display: block;
            background-image: url(../../../img/tickets/bg_mobile_25.png);
            width: 25px;
        }
        &_wrapper {
            width: calc(100% - 25px);
        }
    }
}

@media (max-width: 520px) {
    .ticket_btns {
        flex-wrap: wrap;
        &_buy,
        &_sold{
            flex-basis: 100%;
            margin-right: 0;
            order: 3;
            width: 100%;
        }
        &_more {
            margin-left: 0;
            margin-right: 0px;
        }
        &_more,
        &_alfa {
            margin-bottom: 10px;
            width: calc(50% - 10px);
        }
    }
}

@media (max-width: 500px) {
    .ticket {
        &_information {
            padding: 15px 15px 0 15px;
            flex-direction: column;
            align-items: center;
        }
        &_name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &_title,
            &_text {
                text-align: center;
            }
            &_text {
                max-height: 100%;
            }
        }
        &_price {
            margin-top: 10px;
            &_text {
                margin-top: 10px;
            }
            &_whole {
                width: auto;
            }
        }

        &_btns {
            padding: 15px 15px 15px 15px;
            padding-top: 15px;
        }
    }
}

@media (max-width: 440px) {
    .ticket {
        &_btns {
            &_more,
            &_buy,
            &_sold {
                width: calc(50% - 10px);
            }
        }
    }
}

body {
    margin: 0;
    background: var(--theme-background);
}

.header {
    border-bottom: 3px solid #31DDBF;
    background: #ffffff5b;
    backdrop-filter: blur(7.5px);
    position: fixed;
    z-index: 5;
    width: 100%;
    // border-bottom: 2px solid var(--theme-headerline);
    // box-shadow: 0px 0px 4px #0E4B31;
    // box-shadow: 0 0 5px var(--theme-headerline), 0 0 10px var(--theme-headerline), 0 0 15px var(--theme-headerline), 0 0 20px var(--theme-headerline);
    &_container {
        max-width: 1360px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
    }
    &_logo {
        padding: 7px 0;
        transition: filter 0.3s;
        display: flex;
        justify-content: flex-start;
        // background: #ffffffb3;
        // backdrop-filter: blur(7.5px);
        padding: 2px 12px;
        border-radius: 8px;
        max-width: 130px;
        &_img {
            width: 135px;
            height: 100%;
            transition: filter 0.3s;
            box-shadow: 0px 0px 10px 0px #CCFE01;
            filter: drop-shadow(0px 0px 15px rgb(204, 254, 1));
        }
        // &:hover {
        //     filter: drop-shadow(0px 0px 15px rgb(204, 254, 1));
        //   }
          &:active {
            transform: translateY(2px);
            transition: transform 0.1s ease;
          }
    }
    &_menu {
        &_wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 750px;
            width: 100%;
            margin-right: 0;
            margin-left: auto;
            transition: all .2s ease;
        }
        width: 100%;
        // margin-top: 10px;
        &_item {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: var(--theme-main-ticket-button-text);
            text-decoration: none !important;
            position: relative;
            padding: 10px 18px;
            transition: all .2s ease;
            cursor: pointer;
            // background: #ffffffb3;
            // backdrop-filter: blur(7.5px);
            border-radius: 8px;
            // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            // overflow: hidden;
            &_ticket {
                padding: 12px 18px;
                background: var(--theme-main-ticket-button);
                // box-shadow: 0px 0px 15px rgba(88, 86, 214, 0.5);
                border-radius: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: var(--theme-main-ticket-button-text);
                animation: 2s button-pulse infinite;
                transition: .2s;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            }
            &_ticket:hover {
                color: var(--theme-main-ticket-button-text) !important;
                background: var(--theme-main-ticket-button-hover);
            }
            &_ticket:active {
                background: var(--theme-main-ticket-button-active);
            }
            &_submenu {
                opacity: 0;
                pointer-events: none;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 40px;
                left: 80%;
                border-radius: 10px;
                overflow: hidden;
                min-width: 230px;
                width: 100%;
                transition: opacity .3s ease, max-height 2.7s ease-in-out;
                transform: translate(-50%, 0);
                box-shadow: rgba(114, 114, 114, 0.25) 0px 4px 8px 2px;
                
            }
        }
        &_item:hover {
            color: var(--theme-main-ticket-button-text);
        }
        &_item:hover .header_menu_item_submenu {
            pointer-events: all;
            opacity: 1;
        }
    }
    &_submenu {
        &_item {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: var(--theme-main-ticket-button-text);
            text-decoration: none !important;
            text-align: left;
            padding: 15px 20px;
            background: rgba(255,255,255,0.85);
            -webkit-backdrop-filter: blur(10px);
            transition: .2s ease;
        }
        &_item:not(:last-child) {
            border-bottom: 1px solid #12045C;
        }
        &_item:hover {
            background: #ffffff;
        }
    }
}

.header_menu_item_arrow {
    display: none;
    margin-left: 10px;
    margin-bottom: 2px;
    border: solid #000000;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(45deg);
    transition: transform 0.3s ease-in-out;
}


@media (max-width: 870px) {
    .header_menu_item_arrow {
        display: inline-block;
    }
    
    .header_menu_item_arrow--open {
        margin-top: 2px;
        margin-bottom: 0 !important;
        transform: rotate(-135deg);
    }
    .header {
        &_container {
            position: relative;
        }
        &_menu {
            opacity: 0;
            pointer-events: none;
            transition: .4s ease-in-out;
            background: #071001b8;
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
            height: 100vh;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin-top: 0;
            &_wrapper {
                transition: .4s ease-in-out;
                transform: translateX(100%);
                background: var(--theme-header);
                padding: 70px 0 0 20px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                height: 100%;
                width: 100%;
            }
            &_item {
                font-weight: 700;
                font-size: 18px;
                margin-bottom: 20px;
                order: 2;
                &_ticket {
                    order: 1;
                }
                &_submenu {
                    max-height: 0px;
                    opacity: 1;
                    pointer-events: none;
                    display: flex;
                    flex-direction: column;
                    position: static;
                    top: 0;
                    left: 0px;
                    border-radius: 10px;
                    overflow: hidden;
                    min-width: 0;
                    width: auto;
                    transition: opacity .3s ease, max-height .25s ease;
                    transform: translate(0%, 0);
                    box-shadow: none;
                    //padding-top: 15px;
                    padding-left: 15px;
                    max-height: 0;
                }
            }
            &_close {
                position: absolute;
                right: calc(30% + 30px);
                top: 20px;
                display: block;
                width: 30px;
                height: 30px;
                background: url(../../img/header/close.svg);
                background-size: cover;
                cursor: pointer;
            }
        }
        &_submenu {
            &_item:first-child {
                margin-top: 10px;
            }
            &_item {
                font-weight: 600;
                font-size: 18px;
                line-height: 19px;
                color: #000000;
                text-decoration: none;
                text-align: left;
                padding: 0;
                background: transparent;
                transition: .2s ease;
                margin-bottom: 10px;
            }
            &_item:not(:last-child) {
                border-bottom: none;
            }
            &_item:hover {
                background: transparent;
            }
        }
        &_mobile {
            position: absolute;
            right: calc(30px);
            top: 22px;
            display: block;
            width: 25px;
            height: 25px;
            background: url(../../img/header/hamburg.svg);
            background-size: cover;
            cursor: pointer;
            transition: .4s ease-in-out;
        }
    }
}

@keyframes button-pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
    }
    80% {
      box-shadow: 0 0 0 15px transparent;
    }
    100% {
      box-shadow: 0 0 0 0 transparent;
    }
  }

  :root {
    --test: rgb(239, 35, 60);
  }
